<template>
  <div class="bigBox">
    <div class="form">
      <el-form
        label-width="150px"
        :model="form"
      >
        <el-form-item label="用户初始金币数量">
          <el-input v-model="form.region" placeholder="请输入初始金币数量"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button type="primary" size="small"  @click="sure"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form:{},
    };
  },
  mounted() {},
  methods: {
    sure() {},
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  width: 360px;
  margin: 0 auto;
  .form{
      margin-top: 40px;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
